<template>
  <div class="ondemand-list">
    <Action-panel :isReady="isPageReady">
      <Button-base
        :type="2"
        :text="translations.labels.button_add_customField"
        :hoverInfo="translations.labels.button_add_customField_hover"
        :onClick="newItem"
      />
    </Action-panel>

    <TableList
      :is-ready="isPageReady"
      :headers="headers"
      :items="model"
      :items-per-page="-1"
      sortable
      hideFooter
      @sorted="saveOrder"
      item-key="id"
      :searchVisible="false"
    />
  </div>
</template>

<script>
import Vue from "vue";
import Component from "vue-class-component";
import Container from "@/components/dom/Container";
import ActionPanel from "@/components/panel/ActionPanel";
import InnerPanel from "@/components/panel/InnerPanel";
import TablePanel from "@/components/panel/TablePanel";
import ButtonBase from "@/components/dom/ButtonBase";
import SvgIcon from "@/components/dom/SvgIcon";
import Round from "@/components/dom/Round";
import TableList from "@/components/table/TableList";

@Component({
  components: {
    ActionPanel,
    InnerPanel,
    TablePanel,
    ButtonBase,
    SvgIcon,
    Round,
    Container,
    TableList,
  },
  inject: {
    customFieldService: "customFieldService",
    toastService: "toastService",
  },
})
export default class CustomFieldList extends Vue {
  isPageReady = false;
  model = [];
  search = "";

  async init() {
    try {
      this.model = await this.customFieldService.list();
      this.isPageReady = true;
    } catch (error) {
      this.toastService.error(this.translations.errors.somethingWrong);
    }
  }

  toastSuccessLabel(isEnabled) {
    isEnabled
      ? this.toastService.success(this.translations.success.customField_disable)
      : this.toastService.success(this.translations.success.customField_enable);
  }

  async swapEnabled(idEvent, isEnabled) {
    const status = isEnabled ? "disable" : "enable";
    try {
      await this.customFieldService.setEnabling(idEvent, status);
      this.model = await this.customFieldService.list();
      this.toastSuccessLabel(isEnabled);
    } catch (error) {
      this.toastService.error(this.translations.errors.somethingWrong);
    }
  }

  newItem() {
    this.$router.push("/configurations/custom-fields/new");
  }

  get translations() {
    return this.$store.state.translationsStore.currentLang;
  }

  get headers() {
    return [
      {
        text: this.translations.labels.customField_table_code,
        value: "code",
      },
      {
        text: this.translations.labels.customField_table_category,
        value: "categoryCode",
      },
      {
        text: this.translations.labels.customField_table_fieldType,
        value: "fieldType",
      },
      {
        text: this.translations.labels.customField_table_isRequired,
        value: "isRequired",
        type: "boolean",
        itemValue: (item) => {
          return item.isRequired;
        },
      },
      {
        text: this.translations.labels.customField_table_isHiddenToUser,
        value: "isHiddenToUser",
        type: "boolean",
        itemValue: (item) => {
          return item.isHiddenToUser;
        },
      },
      {
        text: this.translations.labels.table_detail,
        value: "detail",
        type: "link",
        icon: "mdi-feature-search-outline",
        formatValue: (item) => {
          return `/configurations/custom-fields/detail/${item.id}`;
        },
      },
      {
        text: this.translations.labels.table_edit,
        value: "edit",
        type: "link",
        formatValue: (item) => {
          return `/configurations/custom-fields/edit/${item.id}`;
        },
        icon: "mdi-pencil",
        buttonType: 2,
      },
      {
        text: this.translations.labels.table_enable,
        value: "isEnabled",
        type: "switch",
        itemValue: (item) => {
          return item.isEnabled;
        },
        onAction: (item) => {
          this.swapEnabled(item.id, item.isEnabled);
        },
      },
    ];
  }

  async saveOrder(event) {
    const movedItem = this.model.splice(event.oldIndex, 1);
    this.model.splice(event.newIndex, 0, movedItem[0]);

    let newOrders = [];
    this.model.forEach((m, i) => {
      const index = i + 1;
      this.model[i].order = index;
      newOrders.push({
        id: m.id,
        displayOrder: index,
      });
    });

    try {
      await this.customFieldService.saveOrder(newOrders);
    } catch (e) {
      this.toastService.error(this.translations.errors.somethingWrong);
    }
  }

  mounted() {
    this.init();
    this.$store.commit(
      "SET_SECTION_TITLE",
      this.translations.pageTitles.customFields_list
    );
  }
}
</script>